import _matrix from "./matrix";
var exports = {};
var Matrix = _matrix;
var operations = {
  matrix: true,
  scale: true,
  rotate: true,
  translate: true,
  skewX: true,
  skewY: true
};
var CMD_SPLIT_RE = /\s*(matrix|translate|scale|rotate|skewX|skewY)\s*\(\s*(.+?)\s*\)[\s,]*/;
var PARAMS_SPLIT_RE = /[\s,]+/;

exports = function transformParse(transformString) {
  var matrix = new Matrix();
  var cmd, params; // Split value into ['', 'translate', '10 50', '', 'scale', '2', '', 'rotate',  '-45', '']

  transformString.split(CMD_SPLIT_RE).forEach(function (item) {
    // Skip empty elements
    if (!item.length) {
      return;
    } // remember operation


    if (typeof operations[item] !== "undefined") {
      cmd = item;
      return;
    } // extract params & att operation to matrix


    params = item.split(PARAMS_SPLIT_RE).map(function (i) {
      return +i || 0;
    }); // If params count is not correct - ignore command

    switch (cmd) {
      case "matrix":
        if (params.length === 6) {
          matrix.matrix(params);
        }

        return;

      case "scale":
        if (params.length === 1) {
          matrix.scale(params[0], params[0]);
        } else if (params.length === 2) {
          matrix.scale(params[0], params[1]);
        }

        return;

      case "rotate":
        if (params.length === 1) {
          matrix.rotate(params[0], 0, 0);
        } else if (params.length === 3) {
          matrix.rotate(params[0], params[1], params[2]);
        }

        return;

      case "translate":
        if (params.length === 1) {
          matrix.translate(params[0], 0);
        } else if (params.length === 2) {
          matrix.translate(params[0], params[1]);
        }

        return;

      case "skewX":
        if (params.length === 1) {
          matrix.skewX(params[0]);
        }

        return;

      case "skewY":
        if (params.length === 1) {
          matrix.skewY(params[0]);
        }

        return;
    }
  });
  return matrix;
};

export default exports;